import * as React from "react"
import { Link, graphql } from "gatsby"
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import {GatsbyImage} from 'gatsby-plugin-image'
import { ThemeProvider} from '@material-ui/core/styles'
import {createTheme} from '@mui/system'





import Layout from "../components/layout"
import Seo from "../components/seo"




const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  
  
  const theme = createTheme({
    palette: {
      background: {
        paper: '#181715',
      },
      primary: {
        main: '#181715'
      },
      text: {
        primary: '#181715',
        secondary: '#46505A',
      },
    },
  });

  
  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <div className="galleryH">Blog/Code/Project Gallery</div>
      <ol className="projectGallery" style={{ listStyle: `none` }}>
        {posts.map(post => {       
          const title = post.frontmatter.title || post.fields.slug                       
          const iLink = post.frontmatter.imageLink.childImageSharp.gatsbyImageData
          return (
            
            <Link data-sal="slide-up" key={post.fields.slug} className="itemTile" to={post.fields.slug} itemProp="url">            
            <ThemeProvider theme={theme}>
            <Card sx={{bgcolor: theme.palette.background.paper,}}>
              <CardMedia                 
                height="200"  
                                
              >      
              
              <GatsbyImage image={iLink} alt="" /> 
              </CardMedia>    
              <CardContent className="card-content">                                      
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>                    
                      <span itemProp="headline">{title}</span>                    
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
              </CardContent>
            </Card>      
            </ThemeProvider>      
            </Link>            
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex
export const pageQuery = graphql`
  query {
    allImageSharp {
      edges {
        node {
          id
          fluid {
            originalImg          
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description       
          imageLink {
            childImageSharp {
              gatsbyImageData
            }
          }                    
        }        
      }
    }
  }
`
